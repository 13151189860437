import React from "react";
import { Breadcrumbs } from "@catchoftheday/cg-components";
import { CategoryBreadcrumbsPlaceholder } from "./CategoryBreadcrumbsPlaceholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryBreadcrumbs = function CategoryBreadcrumbs(_ref) {
  var loading = _ref.loading,
      breadcrumbs = _ref.breadcrumbs;

  if (loading) {
    return ___EmotionJSX(CategoryBreadcrumbsPlaceholder, null);
  }

  if (!breadcrumbs.length) {
    return null;
  }

  var mappedBreadcrumbs = breadcrumbs.map(function (category, idx) {
    return {
      label: category.name,
      href: category.url,
      leaf: idx === breadcrumbs.length - 1
    };
  });
  return ___EmotionJSX(Breadcrumbs, {
    breadcrumbs: mappedBreadcrumbs,
    useNextLinks: true,
    listProps: {
      mb: 0
    }
  });
};