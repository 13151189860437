import React from "react";
import { DesktopHeaderZone, HeadingZone, MobileHeaderZone, MobileStickyHeader, SortOptionZone } from "../../layouts/PageLayout/PageLayout";
import { PageTitle } from "../../pure/Header/PageTitle/PageTitle";
import { ToggleFacetsButton } from "../../pure/Header/ToggleFacetsButton/ToggleFacetsButton";
import { SearchSortOptions } from "../../pure/SearchSortOptions/SearchSortOptions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContainer = function HeaderContainer(_ref) {
  var _ref$pageTitle = _ref.pageTitle,
      pageTitle = _ref$pageTitle === void 0 ? "" : _ref$pageTitle,
      sortOptions = _ref.sortOptions,
      _ref$totalFacetsAppli = _ref.totalFacetsApplied,
      totalFacetsApplied = _ref$totalFacetsAppli === void 0 ? 0 : _ref$totalFacetsAppli,
      banner = _ref.banner,
      breadcrumbs = _ref.breadcrumbs;
  // Toggle between mobile and desktop headers as they have different layouts and orderings of components
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MobileStickyHeader, null, ___EmotionJSX(ToggleFacetsButton, {
    totalFacetsApplied: totalFacetsApplied
  }), sortOptions && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
    options: sortOptions
  }))), ___EmotionJSX(MobileHeaderZone, null, banner && ___EmotionJSX(React.Fragment, null, banner), breadcrumbs && ___EmotionJSX(React.Fragment, null, breadcrumbs), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
    title: pageTitle
  }))), ___EmotionJSX(DesktopHeaderZone, null, banner && ___EmotionJSX(React.Fragment, null, banner), breadcrumbs && ___EmotionJSX(React.Fragment, null, breadcrumbs), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
    title: pageTitle
  }), ___EmotionJSX(ToggleFacetsButton, {
    totalFacetsApplied: totalFacetsApplied
  }), sortOptions && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
    options: sortOptions
  })))));
};