import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["isLoggedIn", "isCCMember", "isFlybuysLinked", "wishItemsAmount", "cartItemsAmount", "userName", "isLoading", "myAccountMessageCount", "headerConfiguration", "host", "cookies"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext } from "react";
import ConstructorioClient from "@constructor-io/constructorio-client-javascript";
import { Box, Flex, Image, Link, NoSSR, Picture } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { Container } from "../Container";
import { SearchBarContainer } from "../SearchBarContainer";
import { FlybuysTile } from "./FlybuysTile";
import { MiniCart } from "./MiniCart";
import { MyAccount } from "./MyAccount";
import { WishList } from "./WishList"; // Overriding for web

var LogoContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  @media (max-width: ", ") and (min-width: ", ") {\n    width: 96px;\n  }\n"])), themeGet("breakpoints.lg"), themeGet("breakpoints.md"));
import { AppExperimentContext } from "@catchoftheday/analytics";
import { SearchBarContainer as ConstructorSearchBar } from "@catchoftheday/catalogue";
import { getEnvConfig } from "@catchoftheday/env-config";
import { getSalesChannel } from "@catchoftheday/utilities";
import { LogoDecoration } from "./LogoDecoration";
import { OnePassFlybuysTile } from "./OnePassFlybuysTile";
import { OnePassTile } from "./OnePassTile";
import { jsx as ___EmotionJSX } from "@emotion/react";

var TileFlex = function TileFlex(props) {
  return ___EmotionJSX(Flex, _extends({
    display: {
      xs: "inherit",
      sm: "none",
      lg: "inherit"
    }
  }, props));
};

export var PrimaryHeader = function PrimaryHeader(_ref) {
  var _primaryHeaderConfig$4, _primaryHeaderConfig$5, _primaryHeaderConfig$6, _primaryHeaderConfig$7;

  var isLoggedIn = _ref.isLoggedIn,
      isCCMember = _ref.isCCMember,
      isFlybuysLinked = _ref.isFlybuysLinked,
      wishItemsAmount = _ref.wishItemsAmount,
      cartItemsAmount = _ref.cartItemsAmount,
      userName = _ref.userName,
      isLoading = _ref.isLoading,
      myAccountMessageCount = _ref.myAccountMessageCount,
      headerConfiguration = _ref.headerConfiguration,
      host = _ref.host,
      cookies = _ref.cookies,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["constructor_autocomplete_web_may_24", "constructor_autocomplete_web_nz_oct_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 2),
      constructorAutocompleteExperiment = _getExperiments$exper[0],
      constructorNZAutocompleteExperiment = _getExperiments$exper[1];

  var _getEnvConfig = getEnvConfig(),
      CONSTRUCTOR_API_KEY = _getEnvConfig.CONSTRUCTOR_API_KEY,
      CONSTRUCTOR_NZ_API_KEY = _getEnvConfig.CONSTRUCTOR_NZ_API_KEY,
      CONSTRUCTOR_SERVICE_URL = _getEnvConfig.CONSTRUCTOR_SERVICE_URL;

  var apiKey = getSalesChannel(host) === "CANZ" ? CONSTRUCTOR_NZ_API_KEY : CONSTRUCTOR_API_KEY;
  var enableConstructor = getSalesChannel(host) === "CANZ" && (constructorNZAutocompleteExperiment === null || constructorNZAutocompleteExperiment === void 0 ? void 0 : constructorNZAutocompleteExperiment.bucket) === 1 || getSalesChannel(host) === "COTD" && (constructorAutocompleteExperiment === null || constructorAutocompleteExperiment === void 0 ? void 0 : constructorAutocompleteExperiment.bucket) === 1;
  var primaryHeaderConfig = headerConfiguration === null || headerConfiguration === void 0 ? void 0 : headerConfiguration.primaryHeader;
  var eventTheme = headerConfiguration === null || headerConfiguration === void 0 ? void 0 : headerConfiguration.headerTheme;
  var defaultWidths = ["3.06rem", "70px", "10.71875rem", "8.09rem", "10.71875rem"];
  var themedWidths = ["3.0625rem", "70px", "96px", "8.09375rem", "9.625rem"];
  var constructorClient = new ConstructorioClient(_objectSpread({
    apiKey: apiKey,
    serviceUrl: CONSTRUCTOR_SERVICE_URL,
    clientId: (cookies === null || cookies === void 0 ? void 0 : cookies.cnstrClientId) || "1",
    sessionId: (cookies === null || cookies === void 0 ? void 0 : cookies.cnstrSessionId) || 1
  }, enableConstructor ? {
    testCells: {
      constructorio: "constructor"
    }
  } : {}));

  var renderFeatTiles = function renderFeatTiles() {
    var _primaryHeaderConfig$, _primaryHeaderConfig$2, _primaryHeaderConfig$3;

    return ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$ = primaryHeaderConfig.club) === null || _primaryHeaderConfig$ === void 0 ? void 0 : _primaryHeaderConfig$.clubCatchTileEnabled) != false && ___EmotionJSX(TileFlex, {
      "data-testid": "onepass-header-tile"
    }, ___EmotionJSX(OnePassTile, {
      isLoggedIn: isLoggedIn
    })), !isFlybuysLinked && ___EmotionJSX(React.Fragment, null, isLoggedIn && isCCMember ? ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$2 = primaryHeaderConfig.clubFlybuys) === null || _primaryHeaderConfig$2 === void 0 ? void 0 : _primaryHeaderConfig$2.enabled) != false && ___EmotionJSX(TileFlex, null, ___EmotionJSX(OnePassFlybuysTile, null))) : ___EmotionJSX(React.Fragment, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$3 = primaryHeaderConfig.flybuys) === null || _primaryHeaderConfig$3 === void 0 ? void 0 : _primaryHeaderConfig$3.enabled) != false && ___EmotionJSX(TileFlex, null, ___EmotionJSX(FlybuysTile, null)))));
  };

  return ___EmotionJSX(Box, {
    as: "nav",
    "aria-label": "Primary Header Navigation"
  }, ___EmotionJSX(Container, _extends({
    backgroundColor: "secondary",
    height: {
      sm: "70px",
      md: "95px"
    },
    eventTheme: eventTheme,
    position: "relative",
    zIndex: 2
  }, props), ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    height: "100%"
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    flexGrow: 1,
    justifyContent: ["center", null, null, "flex-start"],
    py: 0
  }, ___EmotionJSX(LogoContainer, {
    pr: ["0.4375rem", "0.875rem", "1.1rem"],
    width: eventTheme ? themedWidths : defaultWidths,
    position: "relative"
  }, ___EmotionJSX(Link, {
    href: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.url) || "/",
    className: "js-dnt",
    "data-tracking": "header-app-logo"
  }, ___EmotionJSX(Picture, {
    "data-testid": "catch-logo"
  }, eventTheme && ___EmotionJSX(LogoDecoration, {
    eventTheme: eventTheme
  }), ___EmotionJSX("source", {
    media: "(max-width: 1024px)",
    srcSet: "https://s.catch.com.au/static/catch/images/logo-condensed-b8974103f2.svg",
    type: "image/svg+xml"
  }), ___EmotionJSX(Image, {
    width: "300",
    height: "132",
    alt: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.alt) || "Catch Logo",
    src: (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : primaryHeaderConfig.logo.imageSrc) || "https://s.catch.com.au/static/catch/images/logo-83d9b21199.svg"
  })))), enableConstructor ? ___EmotionJSX(ConstructorSearchBar, {
    client: constructorClient
  }) : ___EmotionJSX(SearchBarContainer, {
    flexContainerProps: {
      flexGrow: 1,
      pr: ["4px", null, "20px"]
    }
  })), ___EmotionJSX(Flex, {
    color: "white",
    flexBasis: ["auto", null, null, null, "660px"],
    justifyContent: "flex-end",
    display: ["inherit", "none", "inherit", null, null]
  }, !isLoading && ___EmotionJSX(NoSSR, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$4 = primaryHeaderConfig.myAccount) === null || _primaryHeaderConfig$4 === void 0 ? void 0 : _primaryHeaderConfig$4.enabled) != false && ___EmotionJSX(Flex, null, ___EmotionJSX(MyAccount, {
    showFlybuysLink: primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$5 = primaryHeaderConfig.myAccount) === null || _primaryHeaderConfig$5 === void 0 ? void 0 : _primaryHeaderConfig$5.showFlybuysLink,
    isLoggedIn: isLoggedIn,
    isCCMember: isCCMember,
    isFlybuysLinked: isFlybuysLinked,
    userName: userName,
    messageCount: myAccountMessageCount
  })), renderFeatTiles()), ___EmotionJSX(Flex, null, (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$6 = primaryHeaderConfig.wishlist) === null || _primaryHeaderConfig$6 === void 0 ? void 0 : _primaryHeaderConfig$6.enabled) != false && ___EmotionJSX(WishList, {
    wishItemsAmount: wishItemsAmount,
    isLoggedIn: isLoggedIn
  }), (primaryHeaderConfig === null || primaryHeaderConfig === void 0 ? void 0 : (_primaryHeaderConfig$7 = primaryHeaderConfig.cart) === null || _primaryHeaderConfig$7 === void 0 ? void 0 : _primaryHeaderConfig$7.enabled) != false && ___EmotionJSX(MiniCart, {
    cartItemsAmount: cartItemsAmount
  }))))));
};
PrimaryHeader.defaultProps = {
  isLoggedIn: false,
  isCCMember: false,
  isFlybuysLinked: false,
  userName: ""
};