import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect, useRef } from "react";
import { emitCatalogueView, SNOWPLOW_SESSION_ID } from "@catchoftheday/analytics";
import { GlobalContextProvider, SponsoredDisclaimerModal } from "@catchoftheday/common-vendors";
import { CustomerContext, CustomerContextProvider } from "@catchoftheday/customer";
import { convertProductCard, ProductGrid } from "@catchoftheday/product-components";
import { getSalesChannel } from "@catchoftheday/utilities";
import { CataloguePageType } from "../../../consts";
import { CatalogueContext, CatalogueContextProvider } from "../../../contexts/CatalogueContext.jsx";
import { useSearchQuery } from "../../../generated/graphql";
import { useSponsoredProducts } from "../../../hooks/useSponsoredProducts/useSponsoredProducts";
import { mapSearchInput } from "../../../utilities/api";
import { BannerZone, FacetsZone, NoProductsZone, PageLayoutZone, PaginationZone, ProductsZone, ResultsZone } from "../../layouts/PageLayout/PageLayout";
import { CataloguePageMetaTags } from "../../pure/CataloguePageMetaTags/CataloguePageMetaTags";
import { LoadingSpinner } from "../../pure/LoadingSpinner/LoadingSpinner";
import { NoResultsHeader } from "../../pure/NoResults/NoResultsHeader/NoResultsHeader";
import { Pagination } from "../../pure/Pagination/Pagination";
import { BannerContainer } from "../BannerContainer/BannerContainer";
import { FacetContainer } from "../FacetContainer/FacetContainer";
import { HeaderContainer } from "../HeaderContainer/HeaderContainer";
import { NoResultsContainer } from "../NoResultsContainer/NoResultsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";

var CatalogueContainerComponent = function CatalogueContainerComponent(_ref) {
  var _customer$membership, _searchInput$paginati;

  var host = _ref.host,
      customerUuid = _ref.customerUuid,
      spSessionId = _ref.spSessionId,
      isMobile = _ref.isMobile,
      queryParams = _ref.queryParams,
      pageType = _ref.pageType;
  var hasMounted = useRef(false);

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data;

  var isOPMigrationEligible = customer === null || customer === void 0 ? void 0 : (_customer$membership = customer.membership) === null || _customer$membership === void 0 ? void 0 : _customer$membership.eligibleForMigration;

  var _useContext2 = useContext(CatalogueContext),
      searchResponse = _useContext2.searchResponse,
      updateSearchResponse = _useContext2.updateSearchResponse,
      updateSearchLoading = _useContext2.updateSearchLoading,
      safeSearchEnabled = _useContext2.safeSearchEnabled,
      mobileFacetsOpen = _useContext2.mobileFacetsOpen;

  var query = queryParams.query;
  var searchInput = mapSearchInput(queryParams, customerUuid);
  var isCOTD = !!host && getSalesChannel(host) === "COTD";

  var _useSearchQuery = useSearchQuery({
    variables: {
      input: searchInput
    },
    skip: !query,
    errorPolicy: "all",
    onCompleted: function onCompleted(data) {
      updateSearchResponse(data.search);
    },
    notifyOnNetworkStatusChange: true
  }),
      initialData = _useSearchQuery.data,
      loading = _useSearchQuery.loading,
      refetch = _useSearchQuery.refetch;

  useEffect(function () {
    updateSearchLoading(loading);
  }, [loading, updateSearchLoading]);
  useEffect(function () {
    if (!hasMounted.current) {
      // Prevent refetch on initial render
      hasMounted.current = true;
      return;
    }

    refetch({
      input: mapSearchInput(queryParams, customerUuid)
    });
  }, [queryParams, refetch, customerUuid]);

  var _useSponsoredProducts = useSponsoredProducts({
    searchTerm: searchInput.query,
    queryParams: queryParams,
    customerUuid: customerUuid,
    spSessionId: spSessionId,
    isMobile: isMobile,
    skipQuery: ((_searchInput$paginati = searchInput.pagination) === null || _searchInput$paginati === void 0 ? void 0 : _searchInput$paginati.page) !== 1 || !isCOTD
  }),
      sponsoredItems = _useSponsoredProducts.sponsoredItems,
      sponsoredItemsTracking = _useSponsoredProducts.sponsoredItemsTracking;

  var _ref2 = searchResponse || (initialData === null || initialData === void 0 ? void 0 : initialData.search) || {},
      pageTitle = _ref2.pageTitle,
      results = _ref2.results,
      sortOptions = _ref2.sortOptions,
      facets = _ref2.facets,
      pagination = _ref2.pagination,
      categories = _ref2.categories,
      totalResults = _ref2.totalResults,
      metadata = _ref2.metadata;

  useEffect(function () {
    var emitView = function emitView() {
      if (metadata) {
        emitCatalogueView(metadata.snowplowContexts);
      }
    };

    var checkSP = function checkSP(event) {
      if (event.key === SNOWPLOW_SESSION_ID) {
        emitView();
      }
    };

    emitView();
    window.addEventListener("storage", checkSP);
    return function () {
      return window.removeEventListener("storage", checkSP);
    };
  }, [metadata]);

  if (!loading && !(initialData !== null && initialData !== void 0 && initialData.search.results.length) && !(initialData !== null && initialData !== void 0 && initialData.search.facets.appliedFacets.length)) {
    return ___EmotionJSX(NoResultsContainer, {
      searchTerm: query,
      host: host
    });
  }

  var products = results === null || results === void 0 ? void 0 : results.map(function (result) {
    return convertProductCard(_objectSpread(_objectSpread({}, result.product), {}, {
      sourceType: queryParams.st ? parseInt("".concat(queryParams.st), 10) : undefined,
      sourceId: query
    }), result.product.selectedOffer);
  });
  var pageContainsAdultProducts = results === null || results === void 0 ? void 0 : results.some(function (result) {
    return result.product.adult;
  });
  return ___EmotionJSX(PageLayoutZone, _extends({}, pageType === CataloguePageType.SEARCH ? {
    "data-cnstrc-search": true
  } : {
    "data-cnstrc-browse": true
  }, {
    "data-cnstrc-num-results": totalResults
  }), ___EmotionJSX(CataloguePageMetaTags, {
    host: host,
    pageType: pageType,
    metaTitle: metadata === null || metadata === void 0 ? void 0 : metadata.metaTitle,
    metaDescription: metadata === null || metadata === void 0 ? void 0 : metadata.metaDescription
  }), loading && ___EmotionJSX(LoadingSpinner, null), ___EmotionJSX(HeaderContainer, {
    pageTitle: pageTitle,
    sortOptions: sortOptions,
    totalFacetsApplied: facets === null || facets === void 0 ? void 0 : facets.appliedFacets.length,
    banner: !isOPMigrationEligible && ___EmotionJSX(BannerZone, null, ___EmotionJSX(BannerContainer, {
      isCOTD: isCOTD,
      searchQuery: searchInput.query,
      pageType: "SEARCH",
      position: "top"
    }))
  }), ___EmotionJSX(ResultsZone, null, ___EmotionJSX(FacetsZone, {
    mobileFacetsOpen: mobileFacetsOpen
  }, facets && ___EmotionJSX(FacetContainer, {
    facets: facets,
    categories: categories,
    showSafeSearch: pageContainsAdultProducts,
    totalResults: totalResults,
    pageType: pageType
  })), ___EmotionJSX(ProductsZone, null, products !== null && products !== void 0 && products.length ? ___EmotionJSX(ProductGrid, {
    productItems: [].concat(_toConsumableArray(sponsoredItems), _toConsumableArray(products)),
    sponsoredItemsTracking: sponsoredItemsTracking,
    displayAdultBlur: safeSearchEnabled,
    placeholderProps: {
      minHeight: "427px"
    }
  }) : ___EmotionJSX(NoProductsZone, null, ___EmotionJSX(NoResultsHeader, {
    hasAppliedFilters: !!(facets !== null && facets !== void 0 && facets.appliedFacets.length),
    showTitle: false
  })), ___EmotionJSX(PaginationZone, null, pagination && ___EmotionJSX(Pagination, {
    pagination: pagination,
    isMobile: isMobile
  })))), ___EmotionJSX(BannerZone, null, ___EmotionJSX(BannerContainer, {
    isCOTD: isCOTD,
    searchQuery: searchInput.query,
    pageType: "SEARCH",
    position: "bottom"
  })));
};

export var CatalogueSearchContainer = function CatalogueSearchContainer(props) {
  return ___EmotionJSX(CustomerContextProvider, null, ___EmotionJSX(CatalogueContextProvider, null, ___EmotionJSX(GlobalContextProvider, null, ___EmotionJSX(SponsoredDisclaimerModal, null), ___EmotionJSX(CatalogueContainerComponent, props))));
};