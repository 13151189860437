export var SEARCH_SUGGESTION_INDEX_NAME = "Search Suggestions";
export var PRODUCT_SUGGESTION_INDEX_NAME = "Products";
export var SEARCH_TRENDING_TERM_POD_ID = "best_sellers_search_suggestions";
export var SEARCH_TRENDING_PRODUCTS_POD_ID = "best_sellers_products";
export var RECENTLY_VIEWED_PRODUCTS_POD_ID = "recently-viewed-products";
export var SEARCH_INPUT_PLACEHOLDER = "Search for products and brands...";
export var RECENT_SEARCH_TERMS_KEY = "recent-search-terms";
export var MAX_RECENT_SEARCH_TERMS = 10;
export var SEARCH_REDIRECTS_KEY = "search-redirects";
export var SEARCH_REDIRECTS_EXPIRY = 5 * 60; // 5 minutes

export var SEARCH_PAGE_PATH = "/search/v2";
export var MAX_VISIBLE_LIST_FACET_OPTIONS = 10;
export var CATEGORY_FACET_SHOW_MORE_LIMIT = 5;
export var OVER_18_COOKIE_NAME = "over18";
export var TRENDING_TERMS_KEY = "trending-search-terms";
export var ONEPASS_DISPLAY_NAME = "onepass";
export var CUSTOMER_UUID_COOKIE_NAME = "cuuid";
export var IS_MOBILE_COOKIE_NAME = "isMobile";
export var CataloguePageType;

(function (CataloguePageType) {
  CataloguePageType["SEARCH"] = "search";
  CataloguePageType["CATEGORY"] = "category";
  CataloguePageType["BRAND"] = "brand";
  CataloguePageType["SHOP"] = "shop";
  CataloguePageType["SELLER"] = "seller";
})(CataloguePageType || (CataloguePageType = {}));